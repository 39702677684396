import React from "react"
import { HeaderVariants } from "../../components/header"
import SEO from "../../components/seo"
import HomePageLayout from "../../components/layouts/HomePageLayout"
import classes from "./post.module.css"
import FacebookIcon from "../../images/facebook.inline.svg"
import TwitterIcon from "../../images/twitter.inline.svg"
import header from "../../images/reasons.png"

const Post = props => (
  <HomePageLayout headerVariant={HeaderVariants.dark} pageTitle="Blog">
    <SEO title="Blog" />
    <div className={classes.header}>
      <img
        src={"https://picsum.photos/1360/400"}
        alt="Post Image"
        className={classes.headerImage}
      />
      <div className={classes.headerContent}>
        <p className={classes.headerContentText}>
          4 Reasons to Invest in UX Design
        </p>
      </div>
    </div>
    <div className={classes.container}>
      {/*<Link to="/blog" className={classes.link}>*/}
      {/*  <LeftIcon fill="#333" width="15px" />*/}
      {/*  <span className={classes.linkText}>Back</span>*/}
      {/*</Link>*/}
      <div className={classes.postContentGrid}>
        <div>
          <p className={classes.sharePostHeader}>Share Post</p>
          <a
            className={classes.sharePostLink}
            href={`https://twitter.com/intent/tweet?text=www.prunedge.com${props.location.pathname}`}
            target="_blank"
          >
            <TwitterIcon height="18px" />
            <span className={classes.sharePostLinkText}>Tweet Post</span>
          </a>
          <a
            className={classes.sharePostLink}
            href={`http://www.facebook.com/sharer.php?u=www.prunedge.com${props.location.pathname}`}
            target="_blank"
          >
            <FacebookIcon height="18px" />
            <span className={classes.sharePostLinkText}>Facebook Post</span>
          </a>
        </div>
        <div className={classes.postContentWrapper}>
          <h1 className={classes.postHeader}>Becoming a developer</h1>
          <div className={classes.postDetails}>
            <img
              src="https://randomuser.me/api/portraits/women/88.jpg"
              alt="Author Avatar"
              className={classes.postDetailsImage}
            />
            <div className={classes.postDetailsText}>
              <p className={classes.postDetailsAuthor}>Segun Micheal Oroyo</p>
              <p className={classes.postDetailsDate}>Nov 15, 2019</p>
            </div>
          </div>
          <div className={classes.postContent}>
            <p>
              There are still components of uncertainty around the financial
              returns of user experience design. This is probably because of the
              time it takes for a UX-oriented approach to “pay off,” the
              relative uniqueness of the field, and awareness about UX design in
              Nigeria (For people who might not know, UX means User experience
              i.e. a person’s emotions and attitudes towards using a technology
              solution)
            </p>
            <p>
              Just so you know, companies that ignore investing in this field
              will soon find out that they are overtaken by smarter competitors
              who put users first in everything they do.
            </p>
            <p>
              Are you in doubt about why you should invest in UX design? In this
              article, I have come up with 4 main reasons why investment in UX
              is not only profitable to organizations of every kind but actually
              crucial to their success over the long term.
            </p>
            <h3 className={classes.postContentHeader}>
              Spending less Naira on web development
            </h3>
            <p>
              In my years of experience working as a UI/UX designer, I realized
              that quite a number of developers spend 50% of their time
              reworking projects. Let me give you an example: a developer would
              start writing codes without fully understanding the scope of work
              and then changes starts coming in( because customers change their
              mind a lot- It is not exactly their fault as sometimes they are
              faced with new realities) , and the person gets stuck just because
              proper UX-documentation doesn’t exist.
            </p>
            <p>
              With a UX design process in place, designs are tested well before
              they’re handed off to a developer. It is much easier to make
              changes during prototyping and it is also highly cost-effective.
              Buggy codes are more expensive to correct than using the right
              information in the first place. Investing in UX at the beginning
              of a project reduces greatly the occurrence of code defects.
            </p>
            <p>
              Companies that invest in UX ship products out faster because
              developers spend less time on going through an almost endless loop
              of iteration due to lack of proper UX.
            </p>
            <h3 className={classes.postContentHeader}>
              Build customer loyalty and increase word-of-mouth referrals
            </h3>
            <p>
              Successful companies are the ones that put their customers first
              and give them fantastic experiences.
            </p>
            <p>
              There’s a reason for this: Happy customers don’t only spend money
              and return to purchase more, they also recommend the product to
              their friends, become loyal users and your brand advocate.
            </p>
            <p>
              Customers are much more likely to recommend a product to their
              friends via word of mouth. In an independent research, I found out
              that customers who had a positive experience told a minimum of 10
              about it. That’s free advertising from a trusted source, and this
              is all because the goals of the business were aligned with the
              goals of the user. Customers are much more likely to recommend a
              product to their friends via word of mouth. In an independent
              research, I found out that customers who had a positive experience
              told a minimum of 10 about it. That’s free advertising from a
              trusted source, and this is all because the goals of the business
              were aligned with the goals of the user.
            </p>
            <h3 className={classes.postContentHeader}>
              Sales increase exponentially
            </h3>
            <p>
              The more a company invests and focuses on design, the more sales
              they will have. A typical example is Cowrywise - in this context,
              our design team at Prunedge, regardless of the ROI, are determined
              to keep going with Cowrywise just because of their designs are
              awesome, as compared with other close substitutes. When a company
              invests in UX, they are bound to have their sales increase by x%
              compared to companies that were less invested in UX.
            </p>
            <p>
              Even if a company doesn’t commit 100% to become UX-led, a small
              investment in this area will lead to increased sales.
            </p>
            <p>Why do sales increase? A few reasons:</p>
            <ul className={classes.postList}>
              <li>
                <p>
                  Customers can more easily navigate a site and locate what
                  they’re looking for
                </p>
              </li>
              <li>
                <p>
                  Customers who are happy interacting with a site are less
                  likely to become frustrated or disengaged, so they’re more
                  likely to complete transactions
                </p>
              </li>
              <li>
                <p>
                  The product has been created with the target audience in mind
                  and then marketed to them, so the right people find the right
                  products
                </p>
              </li>
              <li>
                <p>
                  The buying process has been made as smooth and delightful as
                  possible so that customers return and recommend
                </p>
              </li>
            </ul>
            <h3 className={classes.postContentHeader}>
              Every naira invested in UX brings an increase in return
            </h3>
            <p>
              How does UX design bring about such a high return? It’s a mixture
              of factors, including lower cost of customer acquisition, lower
              support costs, increased customer retention, and increased market
              share. I have seen the results of this in companies who lead in
              customer experience.
            </p>
            <p>
              In conclusion, the quality of experience can be the deciding
              factor between completing a transaction or giving up halfway,
              recommending a product to a friend or tweeting about an
              unsatisfactory interaction, returning to a site for a repeat
              purchase or going to a competitor.
            </p>
            <p>
              Over time, these seemingly small details add up to losses of
              millions—soon to be billions—of naira(or dollars if you deal in
              foreign currency) a year in revenue. That’s more than a convincing
              reason enough to invest in UX design, don’t you agree?
            </p>
          </div>
        </div>
      </div>
    </div>
  </HomePageLayout>
)

export default Post
